import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions';
import { dashBoardConstants } from "../../../constants";
const constants = dashBoardConstants()

export default function GuageConfig(props) {

    const dispatch = useDispatch()
    const control = useSelector(state => state.variables.selectedControl)
    const config = useSelector(state => state.controls.gridDetails[control])
    const dataSources = useSelector(state => state.datasources.config)


    const handleOnChange = (e, method) => {
        switch (method) {
            case 'query':
            case 'source':
            case 'type':
            case 'minVal':
            case 'maxVal':
                dispatch(actions.upateConfiguration(method, e.target.value, props.control))
                break;
            default:
                console.log(method)
        }
    }

    const handleDataSource = async (e) => {
        handleOnChange(e, 'type')
        const type = e.target.value
        let source = ['Select Source']
        if (dataSources[type].length !== 0) {
            dataSources[type].forEach(element => {
                if (type === element.type) {
                    source.push(element.name)
                }
            });
        }
        let el = ''
        const dataSourcEL = document.getElementById(`datasource-name`)
        source.forEach(element => {
            if (element === 'Select Source') {
                el += `<option value="">${element}</option>`
            } else {
                el += `<option value="${element}">${element}</option>`
            }

        });
        dataSourcEL.innerHTML = el
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <label className="form-label text-light">Source Type:</label>
                    <select className="form-select" aria-label="Default select example" value={config.config.type} onChange={(e) => handleDataSource(e)}>
                        {constants.datasource.map((source) => (
                            <option value={source.value}>{source.name}</option>
                        ))}
                    </select>
                    <label className="form-label text-light mt-1">Source Name:</label>
                    <select
                        className="form-select"
                        id={`datasource-name`}
                        value={config.config.source}
                        onChange={(e) => handleOnChange(e, 'source')}>
                    </select>
                    <div className="mt-1">
                        <label className="form-label text-light">Guage Query:</label>
                        <textarea
                            className="form form-control"
                            placeholder="Query"
                            value={config.config.query}
                            onChange={(e) => handleOnChange(e, 'query')} >
                        </textarea>
                    </div>
                </div>
                <div className="col-md-1">
                    <label className="form-label text-light">Min:</label>
                    <input
                        className="form form-control"
                        type="number"
                        placeholder="Min Value"
                        value={config.config.minVal}
                        onChange={(e) => handleOnChange(e, 'minVal')}
                    />
                    <label className="form-label text-light mt-1">Max:</label>
                    <input
                        className="form form-control"
                        type="number"
                        placeholder="Min Value"
                        value={config.config.maxVal}
                        onChange={(e) => handleOnChange(e, 'maxVal')}
                    />
                </div>
            </div>
        </>
    )
}
