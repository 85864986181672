import React from 'react';
import { Layout } from 'antd';

const { Header, Content, Footer } = Layout;

function HTFooter() {
    return (
        <Footer 
            className="text-muted text-center" 
            style={{ 
                backgroundColor:'#001529d1', 
                padding: 13,
                bottom:0,
                right:0,
                left:0,
                position:'absolute' 
            }}
            >Copyright 2021 © Hawfinch Technologies Private Limited
        </Footer>
    )
}

export default HTFooter
