import React, { useEffect, useState } from 'react';
import DashboardGridLayout from '../../components/ui/layout';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { dashBoardConstants } from '../../constants';
import { getConfig } from '../../pages/dataSource/api';
import { Layout, Menu } from 'antd';
import HTSideNavMenu from "../../components/sideMenu";
import HTHeader from '../../components/header';
import HTFooter from '../../components/footer';
import CommFail from '../../components/comFail';
import {loadPage} from '../../reducers/common';

const { Header, Content, Footer } = Layout;
const constants = dashBoardConstants()

const getPageConfiguration = async () => {
    const res = await fetch(constants.url + 'pageDetails/')
    const data = await res.json()
    if(data){
        return data[0].config
    } else{
        return { type: [], layout: [], gridDetails: [] }
    }
    
}

function Home(props) {

	const dispatch = useDispatch()
    const dataSources = useSelector(state => state.datasources.update.dataSource)
    const serverStatus = useSelector(state => state.common.serverCommunication)

	useEffect(() => {
		const readSource = setInterval(() => {
			getJSONData()
		}, 2000);
		dispatch(loadPage(props.history.location.state))
		getDataSourceConfiguration()
		updatePageConfiguration()
		return () => {
			clearInterval(readSource)
		}
	}, [])

	const updatePageConfiguration = async () => {
		const res = await fetch(constants.dataUrl + 'loadConfig/'+props.history.location.state.id)
		const data = await res.json()
		// dispatch(actions.initPage(data))
	}

	const getDataSourceConfiguration = async () => {
        if (!dataSources) {
            const data = await getConfig('datasource')
            if (!data.show) {
                const config = { json: [], csv: [], mysql: [], influx: [], plc: [] }
                data.forEach(element => {
                    config[element.type].push(element)
                });
                dispatch(actions.updateDataSource(config))
            }
        }
    }


	const getJSONData = async () => {
		try{
			const res = await fetch(`${constants.dataUrl}jsonData`)
			const data = await res.json()
			await dispatch(actions.updateData(data))
			await dispatch(actions.updateServerStatus(true))
		} catch {
			await dispatch(actions.updateServerStatus(false))
		}
		return ''
	}

	return (
	<Layout style={{ minHeight: '100vh' }}>
		<HTSideNavMenu page={props.history.location.state}/>
        <Layout className="site-layout">
			<HTHeader page={props.history.location.state} main={false}/>
          <Content style={{ margin: '0 16px' }}>
			  <div className="" style={{ minHeight: 810, maxHeight: 810, overflowY: 'auto' }}>
				  {serverStatus ? <DashboardGridLayout /> : <CommFail />}
			  </div>
          </Content>
         <HTFooter />
        </Layout>
      </Layout>
	)
}

export default Home;
