import React, { useEffect, useState, memo } from 'react';
import Card from '../../card';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useSelector } from 'react-redux';

function Chart(props) {
  const controlID = props.config.id
  const configDetails = useSelector(state => state.controls.gridDetails[controlID])
  const [value, setControlValue] = useState([])
  const [options, setOptions] = useState({
    darkMode: true,
    grid: { top: 35, right: 38, bottom: 34, left: 56, show: false, backgroundColor: 'transparent' },
    xAxis: {
      type: 'category',
    },
    color: ['#00bcd4', '#cddc39', '#ab47bc', '#bdbdbd', '#ab47bc'],
    legend: [{
      show: true,
      data: [],
      textStyle: { color: '#ffff' }
    }],
    dataZoom: [
      {
        type: "inside",
        level: 0,
        z: 0,
        filterMode: "filter",
        disabled: false,
        zoomLock: false,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveonMouseWheel: false,
        preventDefaultMouseMove: true,
        throttle: 100,
        startValue: 0,
        endValue: 2000
      },
      {
        id: 'dataZoomX',
        bottom: 10,
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'empty',
        start: 20,
        end: 80,
        show: false
      }
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        name: 'Lat',
        axisLine: {
          onZero: false,
        },
        splitLine: {
          lineStyle: {
            color: '#000329'
          }
        },
      },
      {
        show: false,
        type: 'value',
        name: 'Longitude',
        axisLine: {
          onZero: false,
        },
        splitLine: {
          lineStyle: {
            color: 'transparent'
          }
        },
      }
    ],
    series: [],
    tooltip: {
      trigger: 'axis',
    },
  });



  useEffect(() => {
    getData(configDetails.config, props.data)
  }, [JSON.stringify(props.data)])


  const getData = async (axis, data) => {
    try {
      let values = []
      let index = 0
      const time = moment(new Date()).format('HH:mm:ss')
      axis.forEach(yAxis => {
        if (yAxis.query !== '') {
          let val = 0
          // val = data[configDetails.config[index].type][configDetails.config[index].source]
          // const queries = yAxis.query.split('.')
          // queries.forEach(element => {
          //   val = val[element]
          // });
          // // console.log(val)

          val = data[configDetails.key][yAxis.axisName]
          const dataType = typeof (val)
          let prevData;

          if (value[index]) {
            prevData = JSON.parse(JSON.stringify(value[index]))
          } else {
            prevData = {
              data: [],
              type: 'line',
              name: configDetails.config[index].axisName,
              emphasis: {
                focus: 'series'
              },
              onZero: false,
              symbol: 'rect',
              yAxisIndex: index,
              animation: false,
              showSymbol: false,
              smooth: true,
            }
          }
          let newData = 0
          switch (dataType) {
            case 'number':
              newData = val
              prevData.data.push([time, newData])
              values.push(prevData)
              break;
            case 'string':
              newData = parseFloat(val)
              prevData.data.push([time, newData])
              values.push(prevData)
              break;
            default:
              break;
          }
        }
        index += 1
      });
      setControlValue(values)

      const d = JSON.parse(JSON.stringify(options))
      d.series = values
      values.forEach((element, i) => {
        d.yAxis[i].name = configDetails.config[i].axisName
        d.yAxis[i].show = true
      });
      d.legend[0].data = configDetails.config.map((e) => e.axisName)
      setOptions(d)

    } catch (err) {
      console.log(err)
    }
  }


  const cardContent = (config) => {
    return <ReactECharts notMerge={true} option={config} style={{ height: '100%', width: '100%' }} />
  }


  return (
    <Card config={props.config} cardContent={() => cardContent(options)} />
  )
}


export default memo(Chart);

