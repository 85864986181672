import {combineReducers} from 'redux';
import controlReducer from './controls';
import variableReducer from './variables';
import dataSourceReducer from './dataSources';
import commonReducer from './common';


const allReducer = combineReducers({
    controls: controlReducer,
    variables: variableReducer,
    datasources: dataSourceReducer,
    common: commonReducer
    
})

export default allReducer;