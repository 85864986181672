import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions';
import { dashBoardConstants } from "../../../constants";


const constants = dashBoardConstants()

export default function BarChartConfig(props) {

    const dispatch = useDispatch()
    const control = useSelector(state => state.variables.selectedControl)
    const config = useSelector(state => state.controls.gridDetails[control])
    const dataSources = useSelector(state => state.datasources.config)
    const sourceList = useSelector(state => state.datasources.source)


    useEffect(() => {
        updateSourceList()
    }, [JSON.stringify(config.config.type)])


    const updateSourceList = () => {
        const source = sourceList[config.config.type]
        if (source) {
            let el = ''
            const dataSourcEL = document.getElementById(`datasource-name`)
            source.forEach(element => {
                if (element === 'Select Source') {
                    el += `<option value="">${element}</option>`
                } else {
                    el += `<option value="${element}">${element}</option>`
                }

            });
            dataSourcEL.innerHTML = el
        }
        console.log(config.config)
    }


    const handleDataSource = async (e, channel) => {
        handleOnChange(e, channel, 'type')
        const type = e.target.value
        let source = ['Select Source']
        if (dataSources[type] !== undefined) {
            if (dataSources[type].length !== 0) {
                dataSources[type].forEach(element => {
                    if (type === element.type) {
                        source.push(element.name)
                    }
                });
            }
        }
        let el = ''
        const dataSourcEL = document.getElementById('datasource-name')
        source.forEach((element, idx) => {
            if (element === 'Select Source') {
                el += `<option key=${'source-options-' + idx} value="">${element}</option>`
            } else {
                el += `<option key=${'source-options-' + idx} value="${element}">${element}</option>`
            }

        });
        dataSourcEL.innerHTML = el

    }

    const handleOnChange = (e, channel, method) => {
        switch (method) {
            case 'query':
            case 'source':
            case 'type':
            case 'axisName':
            case 'columnQuery':
            case 'xAxis':
                dispatch(actions.updateTableConfiguration(method, e.target.value, props.control, channel))
                break;
            default:
                console.log(method)
        }
    }

    const handleAddColumn = (e) => {
        dispatch(actions.updateTableConfiguration('addColumn', 'v', props.control, ''))
    }

    const handleRemoveColumn = (e, channel) => {
        dispatch(actions.updateTableConfiguration('removeColumn', 'v', props.control, channel))
    }


    return (
        <>
            <div className="row">
                <div className="col-md-2">
                    <select className="form-select" aria-label="Default select example" value={config.config.type} onChange={(e) => handleDataSource(e, 0)}>
                        {constants.datasource.map((source) => (
                            <option key={`source-type-${source.name}`} value={source.value}>{source.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    <select
                        className="form-select"
                        id={`datasource-name`}
                        value={config.config.source}
                        onChange={(e) => handleOnChange(e, 0, 'source')}>
                    </select>
                </div>
                <div className="col-md-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Root Element"
                        value={config.config.query}
                        onChange={(e) => handleOnChange(e, 0, 'query')}
                    />
                </div>
                <div className="col-md-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="X-Axis"
                        value={config.config.xAxis}
                        onChange={(e) => handleOnChange(e, 0, 'xAxis')}
                    />
                </div>
                <div className="col-md-3">
                    <input className="form form-control btn btn-warning"
                        type="button" onClick={handleAddColumn}
                        value="+ Add Column"></input>
                </div>
            </div>
            {config.config.columns.map((el, channel) =>
                <div className="row p-0 mb-2" key={`table-config-${channel}`}>
                    <div className="col-md-2">
                        {channel === 0 ? <label className="form-label text-light">Y-Axis Name:</label> : ''}
                        <input
                            type="text"
                            className="form form-control"
                            id={`datasource-axis-name-${channel}`}
                            value={config.config.columns[channel].axisName}
                            onChange={(e) => handleOnChange(e, channel, 'axisName')} />
                    </div>
                    <div className="col-md-5">
                        {channel === 0 ? <label className="form-label text-light">Y-Axis Query:</label> : ''}
                        <input
                            type="text"
                            className="form form-control"
                            placeholder="Query"
                            value={config.config.columns[channel].columnQuery}
                            onChange={(e) => handleOnChange(e, channel, 'columnQuery')} >
                        </input>
                    </div>
                    <div className="col-md-1">
                        {channel === 0 ? <label className="form-label text-light">Delete</label> : ''}
                        <input
                            type="button"
                            className="form form-control"
                            value="Delete"
                            onClick={(e) => handleRemoveColumn(e, channel)}
                        />
                    </div>
                </div>
            )}
        </>
    )
}