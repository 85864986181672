import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../actions';
import { useState, useEffect } from 'react';

import { sourceConfiguration } from '../ui/config';


const Configuration = sourceConfiguration()

export default function EditMenu() {

    const dispatch = useDispatch()
    const control = useSelector(state => state.variables.selectedControl)
    const config = useSelector(state => state.controls.gridDetails[control])
    const [controlName, setControlName] = useState(config.name)
    const [unit, setControlUnit] = useState('')
    const [color, setControlColor] = useState('')
    const dataSources = useSelector(state => state.datasources.update.dataSource)

    useEffect(() => {
        initializeDefault()
        // getDataSourceConfiguration()
    }, [config])



    const initializeDefault = () => {
        setControlName(config.name)
        setControlUnit(config.unit)
        setControlColor(config.color)
    }

    const handleOnChange = (e, method) => {
        dispatch(actions.upateConfiguration(method, e.target.value, control))
        switch (method) {
            case 'name':
                setControlName(e.target.value)
                break;
            case 'unit':
                setControlUnit(e.target.value)
                break;
            case 'color':
                setControlColor(e.target.value)
                break;
            default:
                console.log(method)
        }
    }

    const handleConfig = (config) => {
        const Config = Configuration[config]
        return <Config control={control} />
    }

    return (
        <div className="bg-da1rk p-2">
            <div className="row">
                <div className="col-md-2">
                    <h1 className="h6 text-light">Controls Configuration:</h1>
                    <div className="row">
                        <label className="form-label text-light">Name:</label>
                        <input type="text" className="form-control" placeholder="name" value={controlName}
                            onChange={(e) => handleOnChange(e, 'name')}
                        />
                    </div>
                    <div className="row">
                        <label className="form-label text-light">Unit:</label>
                        <input type="text" className="form-control" placeholder="Unit" value={unit}
                            onChange={(e) => handleOnChange(e, 'unit')}
                        />
                    </div>
                    <div className="row">
                        <label className="form-label text-light">Color:</label>
                        <input type="text" className="form-control" placeholder="Color" value={color}
                            onChange={(e) => handleOnChange(e, 'color')}
                        />
                        {/* <ColorPicker config={config} ctrlId={control}/> */}
                    </div>

                </div>
                <div className="col-md-10">
                    <h1 className="h6 text-light">Datasource Configuration:</h1>
                    {handleConfig(config.control)}
                </div>
            </div>
            <div className="row mt-3">


            </div>
        </div>
    )
}