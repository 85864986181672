import React, { useEffect, useState } from 'react';
import Card from '../../card';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

export default function EChart(props) {
  const controlID = props.config.id
  const configDetails = useSelector(state => state.controls.gridDetails[controlID])

  const yAxisConfig = {
    type: 'value',
    show: true,
    name: '',
    min: 0,
    max: 100,
    splitLine: {
      lineStyle: {
        color: 'transparent'
      }
    }
  }

  const seriesConfig = {
    name: '',
    type: 'line',
    symbol: 'none',
    yAxisIndex: 0,
    lineStyle: {
      width: 1
    },
    encode: {},
    animation: false,
    smooth: true,
  }

  const op = {
    darkMode: true,
    grid: { top: 42, right: 88, bottom: 84, left: 96, show: false, backgroundColor: 'transparent' },
    xAxis: { type: 'category' },
    tooltip: {
      trigger: 'axis',
      transitionDuration: 0,
      alwaysShowContent: false,
      hideDelay: 10,
      backgroundColor: '#011d38',
      borderColor: '#011d38',
      textStyle: {
        color: '#fff'
      },
      position: function (pt) {
        return [pt[0], '10%'];
      }
    },
    legend: {
      data: [],
      icon: 'roundRect',
      textStyle: {
        color: '#fff'
      }
    },
    dataZoom: [
      {
        type: "inside",
        level: 0,
        z: 0,
        filterMode: "filter",
        disabled: false,
        zoomLock: false,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveonMouseWheel: false,
        preventDefaultMouseMove: true,
        throttle: 100,
        startValue: 0,
        endValue: 2000
      },
      {
        id: 'dataZoomX',
        bottom: 10,
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'empty',
        start: 20,
        end: 30,
        show: true
      }
    ],
    yAxis: [],
    series: [],
  };


  const [options, setOptions] = useState({});

  useEffect(() => {
    if (configDetails.config.columns[0]) {
      getData(configDetails.config.columns[0].columnQuery, props.data)
    }
  }, [JSON.stringify(props.data), JSON.stringify(configDetails)])


  const getData = (query, data) => {
    try {
      if (query !== '') {
        const xAxis = configDetails.config.xAxis
        const axis = configDetails.config.columns.map(e => e.columnQuery)
        let index = 0
        let prevData = JSON.parse(JSON.stringify(op))

        axis.forEach(element => {

          prevData.series[index] = JSON.parse(JSON.stringify(seriesConfig))
          prevData.series[index].yAxisIndex = index === 0 ? 0 : 1
          prevData.yAxis[index] = JSON.parse(JSON.stringify(yAxisConfig))
          prevData.yAxis[index].show = index <= 1 ? true : false
          prevData.yAxis[index].name = configDetails.config.columns[index].axisName
          prevData.series[index].name = configDetails.config.columns[index].axisName
          prevData.legend.data[index] = configDetails.config.columns[index].axisName

          let val = 0
          if (configDetails.config.query === '') {
            val = data[configDetails.config.type][configDetails.config.source]
          } else {
            val = data[configDetails.config.type][configDetails.config.source][configDetails.config.query]
          }
          let actualData = val.map(e => e[configDetails.config.columns[index].columnQuery])

          prevData.yAxis[index].max = (Math.max(...actualData) + Math.max(...actualData) * 0.1).toFixed(1)
          prevData.yAxis[index].min = (Math.min(...actualData) + Math.min(...actualData) * 0.1).toFixed(1)

          prevData['dataset'] = { source: val }
          prevData.series[index]['encode'] = { x: xAxis, y: configDetails.config.columns[index].columnQuery }
          index += 1
        });
        setOptions(prevData)
      }
    } catch (err) {
      console.log(err)
    }
  }


  const cardContent = () => {
    if (options !== {}) {
      return <ReactECharts option={options} style={{ height: '100%', width: '100%' }} />
    }

    return <></>
  }


  return (
    <Card config={props.config} cardContent={cardContent} />
  )
}

