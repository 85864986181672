import React, { useState } from 'react';
import { Layout, Menu, Switch, message, Modal, Button } from 'antd';
import { buttonData } from "../toolBar/data";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatabaseOutlined, SaveOutlined, SaveFilled, MenuOutlined } from '@ant-design/icons';
import { dashBoardConstants } from '../../constants';
import ReactTooltip from 'react-tooltip';
import * as actions from '../../actions';


const Buttons = buttonData()
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const constants = dashBoardConstants()


export default function HTSideNavMenu(props) {

  const [collapsed, setcollapsed] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const pageConfiguration = useSelector(state => state.controls)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageName, setPageName] = useState('')

  const dispatch = useDispatch()

  const onCollapse = collapsed => {
    setcollapsed(collapsed);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    updateConfiguration()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };



  const updateConfiguration = async () => {
    try{
      const res1 = await fetch(`${constants.dataUrl}setConfig`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          page: props.page.id,
          config: {"name": props.page.name, "config": pageConfiguration}
        })
      })
      const ra = await res1.json()
      await message.success(ra.message)
    } catch (err){
      message.error('Something went wrong. Please try again later')
    }
  }


  const onClick = ({ key }) => {

    switch(key){
      case 'datasource':
        break;
      case 'savePage':
        updateConfiguration()
        break;
      case 'saveAsPage':
        showModal()
        break;
      default:
        const keys = key.split('-')
        const details = Buttons.indicatorButtons[keys[2]].actions[keys[3]]
        dispatch(details)
    }
  };



  const onChange = (checked) => {
    setEditMode(checked)
    dispatch(actions.updateEditMode(checked))
  }


  return (
    <Sider
      collapsible collapsed={collapsed} onCollapse={onCollapse}
      style={{
        overflow: 'auto',
        height: '100vh',
      }}
    >
      <div className="logo text-center bg-lig1ht">
        <Link className="nav-link" to="/"><img src="https://hawfinch.in/wp-content/uploads/2021/03/Hawfinch_Small-206x63.png" width={168}></img></Link>
        <Switch defaultChecked onChange={onChange} defaultChecked={editMode}/>

      </div>
      {editMode ?
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['']}
        mode="inline"
        theme="dark"
        onClick={onClick}
        inlineCollapsed={collapsed}
      > 
        <Menu.Item className="text-muted" key={`homePage`} icon={<MenuOutlined />}>
          <Link className="nav-link" to="/dashboard">Home</Link>
        </Menu.Item>
        <Menu.Item className="text-muted" key={`datasource`} icon={<DatabaseOutlined />}>
          <Link className="nav-link" to="/data-source" >Data Source</Link>
        </Menu.Item>
        <ReactTooltip place="top" type="dark" effect="float" />

        <Menu.Item className="text-muted" data-tip='Save' key={`savePage`} icon={<SaveOutlined />}>
        </Menu.Item>
        <ReactTooltip place="top" type="dark" effect="float" />
        
        {Buttons.indicatorButtons.map((el, idx) => (
          <SubMenu key={`${el.tooltip}${idx}`} icon={el.icon} title={el.tooltip}>
            {el.types.map((e, index) => {
              const subMenuIcon = el.icons[index]
              return <Menu.Item className="text-mu1ted" icon={subMenuIcon ? subMenuIcon : ''} key={`${el.tooltip}-${e}-${idx}-${index}`}>{e}</Menu.Item>
            })}
          </SubMenu>
        ))}
      </Menu>
      :
      <></>}
      <div className="pageDetails">
        <Modal 
          title="Save Page Configuration" 
          theme='dark' 
          visible={isModalVisible} 
          onOk={handleOk} 
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={false} onClick={handleOk}>
              Submit
            </Button>,
          ]}
          >
            <input 
            type="text" 
            className="form form-control" 
            placeholder="Page Name" 
            onChange={(e) => {
              setPageName(e.target.value)
            }} 
            value={pageName} 
            />
        </Modal>
      </div>
    </Sider>
  )
}