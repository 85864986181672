import { initPage } from '../actions';
import { dashBoardConstants } from '../constants';

const constants = dashBoardConstants()

const commonReducer = (state = {serverCommunication: false, edit: false }, action) => {
    switch (action.type) {
        case 'SERVER STATUS':
            state.serverCommunication = action.value
            return JSON.parse(JSON.stringify(state))
        case 'EDIT MODE':
            state.edit = action.value
            return JSON.parse(JSON.stringify(state))
        default:
            return state
    }
}


export const loadPage = (val) => async (dispatch, getState) => {

    const pages = await fetch(constants.url + 'pageDetails/'+ val.id).then(res => res.json())
    dispatch(initPage(pages[0].config))
} 

export default commonReducer;