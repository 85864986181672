import React, { useEffect, useState } from 'react';
import Card from '../../card';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';

export default function GuageControl(props) {

    const controlID = props.config.id
    const configDetails = useSelector(state => state.controls.gridDetails[controlID])
    const [value, setControlValue] = useState(0)
    const [config, setconfig] = useState({
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
        },

        series: [{
            name: '',
            // startAngle: 180,
            // endAngle: 0,
            type: 'gauge',
            min: -200,
            max: 200,
            itemStyle: {
                color: '#58D9F9',
                // shadowColor: 'rgba(0,138,255,0.45)',
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            },
            detail: {
                formatter: '{value}',
                color: '#58D9F9',
                show: true,
                precision: 1,
                fontSize:15
            },
            pointer: {
                icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                length: '90%',
                width: 10,
                offsetCenter: [0, '5%']
            },
            axisLine: {
                roundCap: true,
                lineStyle: {
                    width: 5,
                }
            },
            axisTick: {
                splitNumber: 2,
                distance: -15,
                lineStyle: {
                    width: 2,
                    color: '#999'
                }
            },
            splitLine: {
                distance: -18,
                length: 18,
                lineStyle: {
                    color: '#ffff'
                }
            },
            axisLabel: {
                distance: -25,
                color: '#ffff',
                fontSize: 9
            },
            title: {
                color: '#ffff',
                fontSize: 11
            },
            data: [{
                value: 50,
                name: 'SCORE',
            }]
        }]
    })

    useEffect(() => {
        getData(configDetails.config.query, props.data)
    }, [JSON.stringify(props.data)])


    const getData = async (query, data) => {
        let val = 0
        try {
            if (query !== '') {
                val = data[configDetails.config.type][configDetails.config.source]
                const queries = query.split('.')
                queries.forEach(element => {
                    val = val[element]
                    // console.log(val)
                });
            }
        } catch (err) {

        }

        const dataType = typeof (val)
        const prevData = JSON.parse(JSON.stringify(config))
        prevData.series[0].data[0].name = configDetails.name
        prevData.series[0].min = configDetails.config.minVal
        prevData.series[0].max = configDetails.config.maxVal
        prevData.series[0].name = configDetails.name
        prevData.series[0].itemStyle.color = configDetails.color
        prevData.series[0].detail.color = configDetails.color

        switch (dataType) {
            case 'number':
                prevData.series[0].data[0].value = val.toFixed(3)
                setControlValue(val.toFixed(3))
                break;
            case 'string':
                prevData.series[0].data[0].value = parseFloat(val).toFixed(3)
                setControlValue(parseFloat(val).toFixed(3) || 0)
                break;
            default:
                setControlValue(0)
        }
        setconfig(prevData)
    }

    const cardContent = () => (
        <ReactECharts option={config} style={{ height: '100%', width: '100%' }} />
    )

    return (
        <Card config={props.config} cardContent={cardContent} />
    )
}