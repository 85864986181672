import React, {useEffect, useState} from 'react';
import Card from '../../card';
import { useSelector } from 'react-redux';

export default function BooleanIndicator (props){


      const controlID = props.config.id
      const configDetails = useSelector(state => state.controls.gridDetails[controlID])
      const [value, setControlValue] = useState("")
  
      useEffect(() => {
          getData(configDetails.config.query, props.data)
      }, [props.data])
  
  
      const getData = async (query, data) => {
          let val = 0
          try{
              if(query !== ''){
                  val = data[configDetails.config.type][configDetails.config.source]
                  const queries = query.split('.')
                  queries.forEach(element => {
                      val = val[element]
                      // console.log(val)
                  });
              }
          } catch (err) {
  
          }
  
          const dataType = typeof(val)
          switch(dataType){
              case 'boolean':
                  setControlValue(val ? "btn-success" : "btn-danger")
                  break;
              default:
                  setControlValue("btn-danger")
          }
      }
    

    const cardContent = () => {

        return(
              <div className="">
                  <span 
                      className={`btn ${value} shadow rounded-circle`}
                      style={{ width:50, height:50 }}
                      >
                   </span>
              </div>
        )
    }
                

    return(
        <Card config={props.config} cardContent={cardContent} />
    )

}