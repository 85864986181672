import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Home from './pages/home';
import PagesList from './pages/pagesList';
import DataSource from './pages/dataSource';
import AddDataSource from './pages/dataSource/addSource';
import Header from './components/header';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';


export default class App extends React.Component {

  render(){
    return (
      <>
        <Router>
          {/* <Header /> */}
          <Switch>
            <Route path='/data-source' component={DataSource} ></Route>
            <Route path='/add-source' component={AddDataSource}></Route>
            <Route path='/dashboard' component={Home} state={this.props.datasource}></Route>
            <Route path='/' component={PagesList}></Route>
          </Switch>
        </Router>
      </>
    );
  }
}