import { dashBoardConstants } from '../constants';
import pageConfigDetails from '../config/pageConfiguration.json';


// const defaultState = { type: [], layout: [], gridDetails: [] }
const defaultState = pageConfigDetails[0].config
const constants = dashBoardConstants()


const chartConfig = [
    {
        channel: '',
        type: '',
        source: '',
        axisName: '',
        query: ''
    },
    {
        channel: '',
        type: '',
        source: '',
        axisName: '',
        query: ''
    },
    {
        channel: '',
        type: '',
        source: '',
        axisName: '',
        query: ''
    },
    {
        channel: '',
        type: '',
        source: '',
        axisName: '',
        query: ''
    }
]

const tableConfig = {
    channel: '',
    type: '',
    source: '',
    axisName: '',
    columnQuery: '',
    resizable: true
}


const controlsConfig = {
    unit: '',
    source: '',
    query: '',
    type: '',
    minVal: 0,
    maxVal: 100,
    warningLimit: -1,
    faultLimit: -1,
    precision: '1'
}

const tableColumnConfig = { query: '', type: '', columns: [] }
const graphConfiguration = { query: '', type: '', columns: [], xAxis: '' }

const controlReducer = (state = defaultState, action) => {
    const num = state.type.length
    let configuration;
    switch (action.type) {
        case 'INIT PAGE':
            state = action.value
            return JSON.parse(JSON.stringify(state))
            break;
        case "NUMERIC":
        case "BUTTON":
        case "NUMERIC INPUT":
        case 'BOOLEAN OUTPUT':
        case 'NUMERIC REAL INPUT':
        case 'STRING':
        case 'SLIDER':
            configuration = getConfiguration(state, action.value, controlsConfig)
            state.type = state.type.concat(action.type)
            state.layout = state.layout.concat({ i: `controls-${num + 1}`, x: 0, y: num, w: 1, h: 1, minH: 1, maxH: 3 })
            state.gridDetails = state.gridDetails.concat({
                name: `Controls ${num + 1}`,
                key: `controls-${num + 1}`,
                control: action.type,
                unit: '',
                color: action.type !== 'STRING' ? '#42a5f5' : '#f06292',
                config: configuration
            })
            modify(state)
            return JSON.parse(JSON.stringify(state))
        case 'GUAGE':
            configuration = getConfiguration(state, action.value, controlsConfig)
            state.type = state.type.concat(action.type)
            state.layout = state.layout.concat({ i: `controls-${num + 1}`, x: 0, y: num, w: 1, h: 2, minH: 0, maxH: 3, minW: 0, maxW: 2 })
            state.gridDetails = state.gridDetails.concat({
                name: `Controls ${num + 1}`,
                key: `controls-${num + 1}`,
                control: action.type,
                unit: '',
                color: '#42a5f5',
                config: configuration
            })
            modify(state)
            return JSON.parse(JSON.stringify(state))
        case 'CHART':
            configuration = getConfiguration(state, action.value, chartConfig)
            state.type = state.type.concat(action.type)
            state.layout = state.layout.concat({ i: `controls-${num + 1}`, x: 0, y: num, w: 3, h: 2, minH: 1, maxH: 3 })
            state.gridDetails = state.gridDetails.concat({
                name: `Controls ${num + 1}`,
                key: `controls-${num + 1}`,
                control: action.type,
                unit: '',
                color: '#42a5f5',
                config: configuration
            })
            modify(state)
            return JSON.parse(JSON.stringify(state))
        case 'TABLE':
            configuration = getConfiguration(state, action.value, tableColumnConfig)
            state.type = state.type.concat(action.type)
            state.layout = state.layout.concat({ i: `controls-${num + 1}`, x: 0, y: num, w: 3, h: 2, minH: 1, maxH: 5 })
            state.gridDetails = state.gridDetails.concat({
                name: `Controls ${num + 1}`,
                key: `controls-${num + 1}`,
                control: action.type,
                unit: '',
                color: '#42a5f5',
                config: configuration
            })
            modify(state)
            return JSON.parse(JSON.stringify(state))
        case 'E-CHART':
        case 'BAR CHART':
        case 'PIE CHART':
        case 'SCATTER CHART':
            configuration = getConfiguration(state, action.value, graphConfiguration)
            state.type = state.type.concat(action.type)
            state.layout = state.layout.concat({ i: `controls-${num + 1}`, x: 0, y: num, w: 3, h: 2, minH: 1, maxH: 5 })
            state.gridDetails = state.gridDetails.concat({
                name: `Controls ${num + 1}`,
                key: `controls-${num + 1}`,
                control: action.type,
                unit: '',
                color: '#42a5f5',
                config: configuration
            })
            modify(state)
            return JSON.parse(JSON.stringify(state))
        case "UPDATE LAYOUT":
            state.layout = action.payload
            return JSON.parse(JSON.stringify(state))
        case "UPDATE CONFIGURATION":
            switch (action.method) {
                case 'name':
                case 'unit':
                case 'color':
                    state.gridDetails[action.index][action.method] = action.value
                    break;
                case 'query':
                case 'source':
                case 'type':
                case 'minVal':
                case 'maxVal':
                case 'precision':
                case 'warningLimit':
                case 'faultLimit':
                    state.gridDetails[action.index].config[action.method] = action.value
                    break;
                default:
                    console.log(action.method)
            }
            return JSON.parse(JSON.stringify(state))
        case "UPDATE CHART CONFIGURATION":
            switch (action.method) {
                case 'name':
                    state.gridDetails[action.index][action.method] = action.value
                    break;
                case 'unit':
                    state.gridDetails[action.index].config[action.method] = action.value
                    break;
                case 'query':
                case 'axisName':
                case 'source':
                case 'type':
                    state.gridDetails[action.index].config[action.channel - 1][action.method] = action.value
                    break;
                default:
                    console.log(action.method)
            }
            return JSON.parse(JSON.stringify(state))
        case "UPDATE TABLE CONFIGURATION":
            switch (action.method) {
                case 'name':
                    state.gridDetails[action.index].name = action.value
                    break;
                case 'unit':
                    state.gridDetails[action.index].config.unit = action.value
                    break;
                case 'addColumn':
                    state.gridDetails[action.index].config.columns.push(tableConfig)
                    break;
                case 'removeColumn':
                    state.gridDetails[action.index].config.columns.splice(action.channel, 1)
                    break;
                case 'source':
                case 'type':
                case 'query':
                case 'xAxis':
                    state.gridDetails[action.index].config[action.method] = action.value
                    break;
                case 'axisName':
                case 'columnQuery':
                    state.gridDetails[action.index].config.columns[action.channel][action.method] = action.value
                    break;
                default:
                    console.log(action.method)
            }
            return JSON.parse(JSON.stringify(state))
        case 'DELETE CONTROL':
            state.type.splice(action.value, 1)
            state.layout.splice(action.value, 1)
            state.gridDetails.splice(action.value, 1)
            return JSON.parse(JSON.stringify(state))
        case 'COPY CONTROL':
            console.log('control - Copy', state.gridDetails[action.value], action.data)
            return JSON.parse(JSON.stringify(state))
        default:
            return state
    }
}


const modify = (state) => {
    var dataLength = state.layout.length

    for (let index = 0; index < state.layout.length; index++) {
        state.layout[index].y = dataLength
        dataLength -= 1
    }
}

const getConfiguration = (state, v, config) => {
    if (v !== undefined) {
        return state.gridDetails[v].config
    } else {
        return config
    }
}


export default controlReducer;

