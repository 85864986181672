import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { buttonData } from './data';

const Buttons = buttonData()

export default function ToolBar() {

    const dispatch = useDispatch()

    return (
        <div className="d-flex justify-content-between">
            <div className="btn-group me-2" role="group" aria-label="Basic outlined example">
                {Buttons.controlButtons.map((el, idx) => (
                    <React.Fragment key={`control-button-${idx}`}>
                        <button
                            type="button"
                            data-tip={el.tooltip}
                            className="btn btn-outline-dark"
                            onClick={() => dispatch(el.action)}>
                            <i className={el.icon}></i>
                        </button>
                        <ReactTooltip place="top" type="dark" effect="float" />
                    </React.Fragment>
                ))}
            </div>

            <div className="btn-group" role="group" aria-label="Basic outlined example">
                {Buttons.indicatorButtons.map((el, idx) => (
                    <React.Fragment key={`indicator-button-${idx}`}>
                        <button
                            type="button"
                            data-tip={el.tooltip}
                            className="btn btn-outline-dark"
                            onClick={() => dispatch(el.action)}>
                            <i className={el.icon}></i>
                        </button>
                        <ReactTooltip place="top" type="dark" effect="float" />
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}