import ChartConfig from './chart/config';
import EChartConfig from "./eChart/config";
import ScatterChartConfig from "./scatterChart/config";
import GuageConfig from "./guage/config";
import BarChartConfig from "./barChart_e/config";
import BooleanConfig from "./booleanIndicator/config";
import ButtonConfig from "./button/config";
import NumericConfig from "./numeric/config";
import NumIntConfig from "./numericInput/config";
import NumRealConfig from "./numericRealInput/config";
import PieChartConfig from "./pieChart/config";
import SliderConfig from "./slider/config";
import StringConfig from "./string/config";
import TableConfig from "./table/config";


export const sourceConfiguration = () => {

    return {
        "CHART": ChartConfig,
        "BUTTON": ButtonConfig,
        "NUMERIC INPUT": NumIntConfig,
        "NUMERIC REAL INPUT": NumRealConfig,
        "BOOLEAN OUTPUT": BooleanConfig,
        "NUMERIC": NumericConfig,
        "SLIDER": SliderConfig,
        "STRING": StringConfig,
        "BAR CHART": BarChartConfig,
        "PIE CHART": PieChartConfig,
        "E-CHART": EChartConfig,
        "GUAGE": GuageConfig,
        "TABLE": TableConfig,
        "SCATTER CHART": ScatterChartConfig,
    }
}