import React from "react";
import { setConfig } from '../api';
import { notification } from 'antd';

export default class MySQL extends React.Component {

    state = {
        config: {
            name: '',
            type: 'mysql',
            config: {
                host: '',
                databaseName: '',
                username: '',
                password: '',
                timeout: '',
                interval: '',
                tags: []
            }
        },
        parameter: {
            name: '',
            address: ''
        },
        edit: false,
        parameterEdit: false
    }

    componentDidMount = () => {
        try {
            if (this.props.data.type === 'mysql') {
                this.setState({ config: this.props.data })
                this.setState({ edit: true })
            } else {
                this.setState({ edit: false })
            }
        } catch {

        }

    }

    openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
            duration: 3,
            placement: 'bottomRight',
            className: `${type === 'error' ? 'bg-danger' : 'bg-success'} rounded`,
            style: {
                color: '#fff!important'
            },
            description:
                '',
        });
    };

    saveConfig = async (e) => {
        e.preventDefault()
        const method = this.state.edit ? 'PUT' : 'POST'
        const url = this.state.edit ? `datasource/${this.state.config.id}/` : `datasource/`
        const res = await setConfig(url, this.state.config, method)
        this.openNotificationWithIcon(res.error ? 'error' : 'success', res.message)
    }

    handleAddParameter = (e) => {
        const config = JSON.parse(JSON.stringify(this.state.config))
        let tagFound = { exist: false, index: 0 };
        e.preventDefault()
        if (this.state.parameter.name !== '' && this.state.parameter.address !== '') {
            let index = 0
            for (let dataTag of config.config.tags) {
                if (dataTag.name === this.state.parameter.name) {
                    tagFound.exist = true
                    tagFound.index = index
                    break
                }
                index += 1
            }
            if (!tagFound.exist) {
                config.config['tags'].push(this.state.parameter)
                this.setState({ config: config })
            } else {
                // alert('Do you want to update the changes?')
                config.config.tags[tagFound.index] = this.state.parameter
                this.setState({ config: config })
            }
        } else {
            this.openNotificationWithIcon('error', 'Parameter & Address field should not be empty...')
        }
    }

    handleOnChange = (e, t) => {
        const config = this.state.config
        switch (t) {
            case 'name':
                config['name'] = e.target.value
                break;
            default:
                config['config'][t] = e.target.value
        }
        this.setState({ config: config })
    }

    handleParameterOnChange = (e, t) => {
        const parameter = JSON.parse(JSON.stringify(this.state.parameter))
        switch (t) {
            case 'name':
                parameter['name'] = e.target.value
                break;
            default:
                parameter['address'] = e.target.value
        }
        this.setState({ parameter: parameter })
    }
    
    handleEditParameter = (idx) => {
        this.setState({ parameterEdit: true })
        let config = JSON.parse(JSON.stringify(this.state.config))
        this.setState({ parameter: { name: config.config.tags[idx].name, address: config.config.tags[idx].address } })

    }

    handleDeleteParameter = (idx) => {
        let config = JSON.parse(JSON.stringify(this.state.config))
        config.config.tags.splice(idx, 1)
        this.setState({ config: config })
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-5 mb-5 text-center">
                    <div className="col-md-2 offset-md-5">
                        <img src="./mysql.png" alt="datasource-logo" className="mb-4 img-fluid"></img>
                    </div>
                    <h1 className="h1 text-light">MySQL Configuration</h1>
                </div>
                <div className="row vh-100 align-ite1ms-center">
                    <div className="col-md-6 offset-md-3">
                        <div className="card shadow">
                            <div className="card-body">
                                <form className="form">
                                    <div className="row" >
                                        <div className="mb-3">
                                            <label className="form-label">Source Name</label>
                                            <input type="text"
                                                className="form-control"
                                                onChange={(e) => this.handleOnChange(e, 'name')}
                                                value={this.state.config.name}
                                                placeholder="Sample Source Name" />
                                        </div>
                                    </div>

                                    <ul className="nav nav-tabs" id="mysqlTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button 
                                            className="nav-link active" 
                                            id="home-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#mysql" 
                                            type="button" 
                                            role="tab" 
                                            >MYSQL Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button 
                                            className="nav-link" 
                                            id="profile-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#mysqltag" 
                                            type="button" 
                                            role="tab" 
                                            >Tag Details</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="mysqlTabContent">
                                        <div className="tab-pane fade show active" id="mysql" role="tabpanel" aria-labelledby="mysql-tab">
                                            <div className="row mt-3">
                                                <h1 className="h5 text-light">MySQL Connection Details:</h1>
                                                <div className="col">
                                                    <label className="form-label">Host</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'host')}
                                                        value={this.state.config.config.host}
                                                        placeholder="http://localhost:3306" />
                                                </div>
                                                <div className="col">
                                                    <label className="form-label">Database Name</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'databaseName')}
                                                        value={this.state.config.config.databaseName}
                                                        placeholder="Database name" />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="form-label">Authentication Details</label>
                                                <div className="col">
                                                    <input type="text"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'username')}
                                                        value={this.state.config.config.username}
                                                        placeholder="Username" />
                                                </div>
                                                <div className="col">
                                                    <input type="password"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'password')}
                                                        value={this.state.config.config.password}
                                                        placeholder="Password" />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <h1 className="h5 text-light">MySQL Settings:</h1>
                                                <div className="col">
                                                    <label className="form-label">Connection Timeout</label>
                                                    <input type="number"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'timeout')}
                                                        value={this.state.config.config.timeout}
                                                        placeholder="3000" />
                                                </div>
                                                <div className="col">
                                                    <label className="form-label">Interval Time</label>
                                                    <input type="number"
                                                        className="form-control"
                                                        onChange={(e) => this.handleOnChange(e, 'interval')}
                                                        value={this.state.config.config.interval}
                                                        placeholder="3000" />
                                                </div>
                                            </div>                               
                                        </div>
                                        <div className="tab-pane fade" id="mysqltag" role="tabpanel" aria-labelledby="tag-tab">
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        style={{ fontSize: 12 }}
                                                        className="form-control"
                                                        placeholder="SELECT * FROM table"
                                                        onChange={(e) => this.handleParameterOnChange(e, 'address')}
                                                        value={this.state.parameter.address}
                                                    />
                                                </div>
                                                <div className="col-md-8 mt-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        onChange={(e) => this.handleParameterOnChange(e, 'name')}
                                                        value={this.state.parameter.name} />
                                                </div>
                                                <div className="col-md-1 mt-3">
                                                    <button className="btn btn-warning" onClick={this.handleAddParameter}><i className="fas fa-plus-circle"></i></button>
                                                </div>
                                            </div>

                                            <table className="table mt-3 text-light">
                                                <thead>
                                                    <tr className="text-light">
                                                        <th>Query</th>
                                                        <th>Parameter Name</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.config.config.tags && this.state.config.config.tags.map((e, idx) => (
                                                        <tr key={`plc-tag-${idx}`}>
                                                            <td>{e.address}</td>
                                                            <td>{e.name}</td>
                                                            <td><i className="far fa-edit" onClick={() => this.handleEditParameter(idx)} ></i></td>
                                                            <td><i className="far fa-trash-alt" onClick={() => this.handleDeleteParameter(idx)}></i></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div className="row p-2 mt-3">
                                        <button className="col form-control btn btn-warning" onClick={this.saveConfig}>{this.state.edit ? 'Update' : 'Save'}</button>

                                        {/* <input type="submit" className="col me-2 form-control btn btn-warning" value="Save" /> */}
                                        {/* <input type="submit" className="col form-control btn btn-warning" value="Test Connection" /> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}