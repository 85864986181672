const dataSourceReducer = (state = { update: { dataSource: false }, config: {}, json: {}, csv: {}, influx: {}, mysql: {}, source: {} }, action) => {
    switch (action.type) {
        case 'UPADTE JSON':
            state.json = action.value
            return JSON.parse(JSON.stringify(state))
        case 'UPADTE DATA':
            const keys = Object.keys(action.value)
            keys.forEach(element => {
                state[element] = action.value[element]
            });
            return JSON.parse(JSON.stringify(state))
        case 'UPADTE DATASOURCE':
            state.config = action.value
            state.update.dataSource = true

            const sources = Object.keys(action.value)
            sources.forEach(source => {
                let names = ['Select Source']
                action.value[source].forEach(element => {
                    names.push(element.name)
                });
                state.source[source] = names
            });

            return JSON.parse(JSON.stringify(state))
        default:
            return state
    }
}

export default dataSourceReducer;