import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions';
import { dashBoardConstants } from "../../../constants";
const constants = dashBoardConstants()

export default function ChartConfig(props) {

    const dispatch = useDispatch()
    const control = useSelector(state => state.variables.selectedControl)
    const config = useSelector(state => state.controls.gridDetails[control])
    const dataSources = useSelector(state => state.datasources.config)
    const channels = [1, 2]


    const handleDataSource = async (e, channel) => {
        handleOnChange(e, channel, 'type')
        const type = e.target.value
        let source = ['Select Source']
        if (dataSources[type].length !== 0) {
            dataSources[type].forEach(element => {
                if (type === element.type) {
                    source.push(element.name)
                }
            });
        }
        let el = ''
        const dataSourcEL = document.getElementById(`datasource-name-${channel}`)
        source.forEach(element => {
            if (element === 'Select Source') {
                el += `<option key=${'source-'+ element } value="">${element}</option>`
            } else {
                el += `<option key=${'source-'+ element } value="${element}">${element}</option>`
            }

        });
        dataSourcEL.innerHTML = el
    }

    const handleOnChange = (e, channel, method) => {
        switch (method) {
            case 'query':
            case 'source':
            case 'type':
            case 'axisName':
                dispatch(actions.upateChartConfiguration(method, e.target.value, props.control, channel))
                break;
            default:
                console.log(method)
        }
    }


    return (
        <>
            {channels.map((channel) =>
                <div className="row p-0">
                    <div className="col-md-1">
                        {channel === 1 ? <label className="form-label text-light">Channel#:</label> : ''}
                        <p className="form text-center form-control">{channel}</p>
                        {/* <input type="text" value=></input> */}
                    </div>
                    <div className="col-md-2">
                        {channel === 1 ? <label className="form-label text-light">Source Type:</label> : ''}
                        <select className="form-select" aria-label="Default select example" value={config.config[channel - 1].type} onChange={(e) => handleDataSource(e, channel)}>
                            {constants.datasource.map((source) => (
                                <option key={`chart-data-source-${source.name}`} value={source.value}>{source.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        {channel === 1 ? <label className="form-label text-light">Source Name:</label> : ''}
                        <select
                            className="form-select"
                            id={`datasource-name-${channel}`}
                            value={config.config[channel - 1].source}
                            onChange={(e) => handleOnChange(e, channel, 'source')}>
                        </select>
                    </div>
                    <div className="col-md-2">
                        {channel === 1 ? <label className="form-label text-light">Axis Name:</label> : ''}
                        <input
                            type="text"
                            className="form form-control"
                            id={`datasource-axis-name-${channel}`}
                            value={config.config[channel - 1].axisName}
                            onChange={(e) => handleOnChange(e, channel, 'axisName')} />
                    </div>
                    <div className="col-md-5">
                        {channel === 1 ? <label className="form-label text-light">Live Chart Query:</label> : ''}
                        <input
                            type="text"
                            className="form form-control"
                            placeholder="Query"
                            value={config.config[channel - 1].query}
                            onChange={(e) => handleOnChange(e, channel, 'query')} >
                        </input>
                    </div>
                </div>
            )}
        </>
    )
}