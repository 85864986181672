import React, { useEffect, useState, memo } from 'react';
import Card from '../../card';
import { useSelector } from 'react-redux';

function Numeric(props) {

    const controlID = props.config.id
    const configDetails = useSelector(state => state.controls.gridDetails[controlID])
    const [value, setControlValue] = useState(0)
    const [color, setControlColor] = useState('')

    useEffect(() => {
        getData(configDetails.config.query, props.data)
    }, [JSON.stringify(props.data[configDetails.key]), JSON.stringify(configDetails)])


    const getData = async (query, data) => {
        let val = 0
        try {
            if (query !== '') {
                //version 1.0 
                // val = data[configDetails.config.type][configDetails.config.source]
                // const queries = query.split('.')
                // queries.forEach(element => {
                //     val = val[element]
                //     // console.log(val)
                // });

                //Version 2.0
                val = props.data[configDetails.key].value

            }
        } catch (err) {

        }

        const dataType = typeof (val)
        switch (dataType) {
            case 'number':
                setControlValue(val.toFixed(configDetails.config.precision))
                break;
            case 'string':
                setControlValue(parseFloat(val).toFixed(configDetails.config.precision) || 'No Data')
                break;
            default:
                setControlValue('No Data')
        }

        setControlColor(configDetails.color)

        if (configDetails.config.warningLimit !== -1) {
            if (val > configDetails.config.warningLimit) {
                setControlColor('#ffb300')
            }
        }

        if (configDetails.config.faultLimit !== -1) {
            if (val > configDetails.config.faultLimit) {
                setControlColor('#f4511e')
            }
        }
    }



    const cardContent = () => {
        return (
            <h1 className="numericValue h6 text-center fs-2" style={
                { color: color }
            }>{value}</h1>
        )
    }


    return (
        <Card config={props.config} cardContent={cardContent} />
    )
}


export default memo(Numeric);