import React from 'react';
import Lottie from "react-lottie";
import error404 from '../../animations/404.json';

export default function CommFail() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: error404,
    };

    return (
        <div className="text-center">
            <Lottie options={defaultOptions} height={680} />
            <p className="h1 text-warning fw-bold">Hawfinch Server Communication Lost</p>
            <p className="text-light">Please check the communication cable.</p>
        </div>
    )
}
