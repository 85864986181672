import React from "react";
import { useDispatch } from 'react-redux';
import * as actions from '../../../actions';
import {useState} from 'react';

export default function SliderConfig (props){

    const [query, setquery] = useState('')
    const dispatch = useDispatch()

    const handleOnChange = (e, method) => {
        switch(method){
            case 'query':
                setquery(e.target.value)
                dispatch(actions.upateConfiguration('query', e.target.value, props.control))
                break;
            default:
                console.log(method)
        }
    }


        return(
            <div className="mt-1">
                <label className="form-label text-light">Slider Query:</label>
                <textarea 
                    className="form form-control" 
                    placeholder="Query" 
                    value={query} 
                    onChange={(e) => handleOnChange(e, 'query')} >
                </textarea>
            </div>
        )
}