import React from 'react';
import Card from '../../card';
import NumericInput from 'react-numeric-input';

// const colors = ['#0288d1', '#9ccc65', '#fdd835', '#f4511e', '#bbdefb', '#e91e63', '#ba68c8']

export default class NumericRealInputControl extends React.Component{

    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange(checked) {
        this.setState({ checked });
      }

    cardContent = () => {

        return(
              <NumericInput 
                className="form form-control text-center fw-bold" 
                value={0} 
                precision={0}
                />
        )
    }
                
    render(){
    return(
        <Card config={this.props.config} cardContent={this.cardContent} />
    )
  }
}