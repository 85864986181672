export const dashBoardConstants = () => {
    const data = {
        "url": "https://www.dashboard.morke.in/api/v1/",
        "dataUrl": "https://www.server.morke.in/",
        // "url": "http://localhost:5000/api/v1/",
        // "dataUrl": "http://localhost:5001/",
        "datasource": [
            { name: 'Datasource...', value: '' },
            { name: 'JSON', value: 'json' },
            { name: 'CSV', value: 'csv' },
            { name: 'MySQL', value: 'mysql' },
            { name: 'Influx', value: 'influx' },
            { name: 'PLC', value: 'plc' },
        ]
    }
    return data
}