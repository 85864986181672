const variableReducer = (state = {edit:false, selectedControl: null}, action) => {
    switch(action.type){
        case 'EDIT ENABLE':
            state.edit = true
            state.selectedControl = action.selectedControl
            return state
        case 'EDIT DISABLE':
            state.edit = false
            // state.selectedControl = null
            return state
        default:
            return state
    }
}

export default variableReducer;