import * as actions from '../../actions';
import {
    BulbOutlined,
    FontSizeOutlined,
    LineChartOutlined,
    AreaChartOutlined,
    BarChartOutlined,
    PieChartOutlined,
    TableOutlined,
    FieldBinaryOutlined,
    DashboardOutlined,
    DotChartOutlined

} from '@ant-design/icons';

export const buttonData = () => {
    return {
        controlButtons: [
            {
                icon: "fas fa-toggle-on",
                tooltip: "Toggle Switch",
                action: actions.button()
            },
            {
                icon: "fas fa-sort-numeric-up",
                tooltip: "Integer Control",
                action: actions.numericInput()
            },
            {
                icon: "fas fa-sort-numeric-up",
                tooltip: "Real Control",
                action: actions.numericRealInput()
            },
            {
                icon: "fas fa-sliders-h",
                tooltip: "Slider",
                action: actions.slider()
            },
        ],
        indicatorButtons: [
            {
                icon: <BulbOutlined />,
                tooltip: "Boolean",
                action: actions.booleanIndicator(),
                actions: [actions.booleanIndicator()],
                icons: [],
                types: ['Default']
            },
            {
                icon: <FieldBinaryOutlined />,
                tooltip: "Numeric",
                action: actions.numeric(),
                actions: [actions.numeric(), actions.string()],
                icons: [<FieldBinaryOutlined />, <FontSizeOutlined />],
                types: ['NUMERIC', 'STRING']
            },
            {
                icon: <LineChartOutlined />,
                tooltip: "Live Chart",
                action: actions.chart(),
                actions: [actions.chart(), actions.scatterChart(), actions.barChart(), actions.pieChart(), actions.eChart()],
                icons: [<LineChartOutlined />, <DotChartOutlined />, <BarChartOutlined />, <PieChartOutlined />, <AreaChartOutlined />],
                types: ['LIVE CHART', 'SCATTER CHART', 'BAR CHART', 'PIE CHART', 'XY GRAPH']
            },
            {
                icon: <DashboardOutlined />,
                tooltip: "Guage",
                action: actions.guage(),
                actions: [actions.guage()],
                icons: [],
                types: ['Default']
            },
            {
                icon: <TableOutlined />,
                tooltip: "Table",
                action: actions.table(),
                actions: [actions.table()],
                icons: [],
                types: ['Default']
            },
        ]
    }
}