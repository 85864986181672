import React, { useEffect, useState, memo } from 'react';
import Card from '../../card';
import { useSelector } from 'react-redux';
import DataGrid from 'react-data-grid';



function TableControl(props) {

    const controlID = props.config.id
    const configDetails = useSelector(state => state.controls.gridDetails[controlID])
    const [value, setControlValue] = useState([])
    const [columns, setColumns] = useState([])


    useEffect(() => {
        getData(configDetails.config.query, props.data)
    }, [JSON.stringify(props.data), JSON.stringify(configDetails)])


    const getData = async (query, data) => {

        const columnInfo = configDetails.config.columns.map((e, idx) => {
            return {
                name: e.axisName,
                key: e.columnQuery,
                resizable: true,
                sortable: true,
            }
        })

        setColumns(columnInfo)
        // console.log(columnInfo)

        let val = 0
        try {
            if (query === '') {
                val = data[configDetails.config.type][configDetails.config.source]
                // console.log(val)
            } else {
                val = data[configDetails.config.type][configDetails.config.source]
                val = query.split('.').reduce((o, i) => o[i], val)
                // console.log(val, 'deepan')
            }
        } catch (err) {
            console.log('Error while Query Table Data')
        }

        const dataType = typeof (val)
        switch (dataType) {
            case 'object':
                setControlValue(val)
                break;
            default:
                setControlValue([])
        }
    }




    const cardContent = () => {
        return (
            <DataGrid
                columns={columns}
                rows={value}
                style={{ width: '100%', height: '100%', color: '#ffff' }}
            />
        )
    }


    return (
        <Card config={props.config} cardContent={cardContent} />
    )
}


export default memo(TableControl);