import React from 'react';
import Card from '../../card';
import { ResponsiveBarCanvas } from '@nivo/bar';


export default class BarChart extends React.Component{

    constructor(){
      super();
      this.state= {
        data: []
      }
    }

    async componentDidMount () {
        const actuals = await this.getData();
        await console.log(actuals)
        await this.setState({data: actuals})
    }

    getData = async () => {
        const res = await fetch('https://coronavirus-19-api.herokuapp.com/countries')
        const data = await res.json()
        return data
    }

    cardContent = () => (
      <ResponsiveBarCanvas
          data={this.state.data}
          keys={[ 'casesPerOneMillion', 'deathsPerOneMillion']}
          indexBy="country"
          margin={{ top: 10, right: 15, bottom: 100, left: 100 }}
          padding={0.5}
        //   groupMode={'grouped'}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          theme={{ 
            textColor:'#ffff',
            "grid": {
              "line": {
                  "stroke": "#dddddd",
                  "strokeWidth": 0.2
              }
          },
          }}
          defs={[
              {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true
              },
              {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
              }
          ]}
          borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          axisBottom={{
              legend: 'country',
              legendPosition: 'right',
              legendOffset: 30,
          }}
        //   axisLeft={{
        //       legend: 'country',
        //       legendPosition: 'middle',
        //       legendOffset: -65,
        //   }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          legends={[
              {
                  dataFrom: 'keys',
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 65,
                  itemsSpacing: 32,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 10,
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
          animate={false}
          motionStiffness={90}
          motionDamping={15}
      />
  )
                
    render(){
    return(
        <Card config={this.props.config} cardContent={this.cardContent} />
    )
  }
}