import React from "react";

export default class Influx extends React.Component{
    render(){
        return(
            <div className="container">
                <div className="row mt-5 mb-5 text-center">
                    <div className="col-md-2 offset-md-5">
                        <img src="./influx.png" alt="datasource-logo" className="mb-4 img-fluid"></img>
                    </div>
                    <h1 className="h1 text-light">InfluxDB Configuration</h1>
                </div>
                <div className="row vh-100 align-ite1ms-center">
                    <div className="col-md-6 offset-md-3">
                        <div className="card shadow">
                            <div className="card-body">
                                
                                <form className="form">
                                     
                                    <div className="row" >
                                        <div className="mb-3">
                                            <label className="form-label">Source Name</label>
                                            <input type="text" className="form-control" id="mysql-name" placeholder="Sample Database" />
                                        </div>
                                    </div>
                                        <div className="row mb-3">
                                            <h1 className="h5 text-light">InfluxDB Connection Details:</h1>
                                            <div className="col">
                                                <label className="form-label">Host</label>
                                                <input type="text" className="form-control" placeholder="http://localhost:8086" />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Database Name</label>
                                                <input type="text" className="form-control" placeholder="Database name" />
                                            </div>
                                        </div>
                                    
                                    <div className="row mb-3">
                                        <label className="form-label">Authentication Details</label>
                                        <div className="col">
                                            <input type="number" className="form-control" min="0" max="255" placeholder="Username" aria-label="Username" />
                                        </div>
                                        <div className="col">
                                            <input type="password" className="form-control" min="0" max="255" placeholder="Password" aria-label="Password" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                            <h1 className="h5 text-light">InfluxDB Settings:</h1>
                                            <div className="col">
                                                <label className="form-label">Connection Timeout</label>
                                                <input type="number" className="form-control" placeholder="3000" />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Interval Time</label>
                                                <input type="number" className="form-control" placeholder="2000" />
                                            </div>
                                    </div>

                                    <div className="row p-2 mt-3">
                                        <input type="submit" className="col me-2 form-control btn btn-warning" value="Save" />
                                        <input type="submit" className="col form-control btn btn-warning" value="Test Connection" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}