import React from "react";
import { Link } from 'react-router-dom';
import { getConfig, deleteConfig } from './api';
import { notification } from 'antd';
import HTHeader from '../../components/header';

export default class DataSource extends React.Component {

    state = {
        dataSource: [],
    }

    componentDidMount = async () => {
        this.getDataSourceConfiguration()
    }

    getDataSourceConfiguration = async () => {
        const data = await getConfig('datasource')
        if (!data.show) {
            this.setState({ dataSource: data })
        }
    }

    openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
            duration: 3,
            placement: 'bottomRight',
            className: `${type === 'error' ? 'bg-danger' : 'bg-success'} rounded`,
            style: {
                color: '#fff!important'
            },
            description:
                '',
        });
    };


    updateDataSource = (data) => {
        switch (data.type) {
            case 'csv':
                return <span className="text-muted"> {data.config.url} </span>
            case 'json':
                return <span className="text-muted"> {data.config.url} </span>
            default:
                console.log('')
        }
    }

    handleEdit = (type) => {
        switch (type) {
            case 'csv':
                break;
            case 'json':
                return ''
            default:
                console.log(type)
        }
    }

    handleDelete = async (id) => {
        const res = await deleteConfig(`datasource/${id}`)
        this.openNotificationWithIcon(res.error ? 'error' : 'success', res.message)
        if (!res.error) {
            this.getDataSourceConfiguration()
        }
    }

    renderDataSourceCard = (el) => {
        return (
            <>
                <li className="list-group-item border-0 bg-transparent mb-2" id={`datasource-list-${el.id}`}>
                    <div className="card shadow p-2 text-light">
                        <div className="row">
                            <div className="text-center col-md-3">
                                <img src={`./${el.type}.png`} alt="datasource-logo" height={100} width={150} className="img-fluid"></img>
                            </div>
                            <div className="col-md-9 align-self-center">
                                <h1 className="h5 heading text-light"><span className="text-warning">{el.name}</span></h1>
                                {this.updateDataSource(el)}
                                <Link to={{
                                    pathname: '/add-source',
                                    state: { type: el.type, data: el }
                                }} className="btn btn-warning mx-2"><i className="fas fa-edit"></i> Edit</Link>
                                <button onClick={() => this.handleDelete(el.id)} className="btn btn-warning"><i className="fas fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </li>
            </>
        )
    }

    render() {
        return (
            <div className="container-fluid mt-0">
                <HTHeader />
                <div className="text-center">
                    <h1 className="h2 heading">Data Source Configuration</h1>
                    <Link className="btn btn-warning mt-2 mb-4" to='/add-source'>Add DataSource</Link>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="datasource-list">
                            <div className="card bg-transparent border-0">
                                <ul className="list-group">
                                    {this.state.dataSource.map((el, idx) => (
                                        <li key={`datasource-list-${idx}`}
                                            className="list-group-item text-light" style={{ background: 'transparent' }}>
                                            <div className="card ">
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <p className="m-0"><span style={{ fontSize: 20 }}>{el.name}</span></p>
                                                            <p className="p-0 m-0"> <img src={`./${el.type}.png`} alt="datasource-logo" height={10} width={50} className="img-fluid"></img></p>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <Link to={{
                                                                pathname: '/add-source',
                                                                state: { type: el.type, data: el }
                                                            }} className="btn text-warning mx-2"><i className="fas fa-edit"></i></Link>
                                                            <button onClick={() => this.handleDelete(el.id)} className="btn text-danger"><i className="fas fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}