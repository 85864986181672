import React from 'react';
import Card from '../../card';
import Switch from "react-switch";


// const colors = ['#0288d1', '#9ccc65', '#fdd835', '#f4511e', '#bbdefb', '#e91e63', '#ba68c8']

export default class Button extends React.Component{

    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange(checked) {
        this.setState({ checked });
      }

    cardContent = () => {

        return(
            <Switch 
                onChange={this.handleChange} 
                checked={this.state.checked} 
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={40}
                width={88}
                className="react-switch"
                id="material-switch"
                />
        )
    }
                
    render(){
    return(
        <Card config={this.props.config} cardContent={this.cardContent} />
    )
  }
}