import React, { useEffect, useState } from 'react';
import Card from '../../card';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';

export default function PieChart(props) {
  const controlID = props.config.id
  const configDetails = useSelector(state => state.controls.gridDetails[controlID])

  const op = {
    darkMode: true,
    grid: { top: 42, right: 88, bottom: 84, left: 96, show: false, backgroundColor: 'transparent' },
    legend: {
      orient: 'vertical',
      type: 'scroll',
      left: 'right',
      textStyle: { color: '#ffff' }
    },
    tooltip: {},
    dataset: {
      source: []
    },
    xAxis: { type: 'category', backgroundColor: 'transparent', show: false },
    yAxis: { backgroundColor: 'transparent' },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
      },
    ]
  };


  const [options, setOptions] = useState({});

  useEffect(() => {
    if (configDetails.config.columns[0]) {
      getData(configDetails.config.columns[0].columnQuery, props.data)
    }
  }, [JSON.stringify(props.data), JSON.stringify(configDetails)])


  const getData = (query, data) => {
    try {
      if (query !== '') {
        const xAxis = configDetails.config.xAxis
        const axis = configDetails.config.columns.map(e => e.columnQuery)
        let val = 0
        let prevData = JSON.parse(JSON.stringify(op))
        if (configDetails.config.query === '') {
          val = data[configDetails.config.type][configDetails.config.source]
        } else {
          val = data[configDetails.config.type][configDetails.config.source][configDetails.config.query]
        }
        let actualData = []
        const name = configDetails.name

        axis.forEach(element => {
          let pieData = {}
          pieData[name] = element
          pieData['value'] = val[element]
          actualData.push(pieData)
        });
        prevData['dataset'] = { source: actualData }
        setOptions(prevData)
      }
    } catch (err) {
      console.log(err)
    }
  }


  const cardContent = () => {
    if (options !== {}) {
      return <ReactECharts notMerge={true} option={options} style={{ height: '100%', width: '100%' }} />
    }

    return <></>
  }


  return (
    <Card config={props.config} cardContent={cardContent} />
  )
}

