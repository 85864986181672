import React, { useState, useEffect, useMemo, memo } from 'react';
import Card from '../../card';
import { useSelector } from 'react-redux';


function StringControl(props) {

    const controlID = props.config.id
    const configDetails = useSelector(state => state.controls.gridDetails[controlID])
    const [value, setControlValue] = useState(0)
    
    const controlData = useMemo(() => {
        if(props.data[configDetails.config.type]){
            return props.data[configDetails.config.type][configDetails.config.source]
        } else {
            return []
        }
    }, [props.data])

    useEffect(() => {
        getData(configDetails.config.query, props.data)
    }, [JSON.stringify(props.data[configDetails.key]), JSON.stringify(configDetails)])


    const getData = async (query, data) => {
        let val = 0
        try {
            if (query !== '') {
                //version 1.0 
                // val = data[configDetails.config.type][configDetails.config.source]
                // const queries = query.split('.')
                // queries.forEach(element => {
                //     val = val[element]
                //     // console.log(val)
                // });

                //Version 2.0
                val = props.data[configDetails.key].value
            }
        } catch (err) {

        }

        const dataType = typeof (val)
        switch (dataType) {
            case 'number':
                setControlValue(String(val) || 'No Data')
                break;
            case 'string':
                setControlValue(val || 'No Data')
                break;
            default:
                setControlValue('No Data')
        }
    }

    const cardContent = () => {
        return (
            <h1 className="numericValue h6 text-center fs-2" style={{ color: configDetails.color }}>{value}</h1>
        )
    }


    return (
        <Card config={props.config} cardContent={cardContent} />
    )
}


export default memo(StringControl);