import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../actions';
import Numeric from '../numeric';
import Button from '../button';
import NumericInputControl from '../numericInput';
import NumericRealInputControl from '../numericRealInput';
import BooleanIndicator from '../booleanIndicator';
import BarChart from '../barChart';
import BarChartNew from '../barChart_e';
import ScatterChart from '../scatterChart';
import Chart from '../chart';
import Echart from '../eChart';
import TableControl from '../table';
import PieChart from '../pieChart';
import GuageControl from '../guage';
import SliderControl from '../slider';
import StringControl from '../string';
import { Drawer } from 'antd';
import EditMenu from '../../editMenu';


export default function DashboardGridLayout() {


  const layout = useSelector(state => state.controls.layout)
  const grid = useSelector(state => state.controls.gridDetails)
  const visible = useSelector(state => state.variables.edit)
  const data = useSelector(state => state.datasources)
  const controlsConfig = useSelector(state => state.controls)
  const [prevConfig, setPrevConfig] = useState([])
  const dispatch = useDispatch()



  const onClose = () => {
    dispatch(actions.disableDrawer())
  };


  const onLayoutChange = (layouts) => {
    dispatch(actions.updateLayout(layouts))
  }

  const onResize = (layouts => {
    dispatch(actions.updateLayout(layouts))
  })

  const control = (type, config, key) => {
    switch (type) {
      case "NUMERIC":
        return <Numeric config={config} keyId={key} data={data} />
      case "BUTTON":
        return <Button config={config} keyId={key} data={data} />
      case "NUMERIC INPUT":
        return <NumericInputControl config={config} keyId={key} data={data} />
      case "NUMERIC REAL INPUT":
        return <NumericRealInputControl config={config} keyId={key} data={data} />
      case "BOOLEAN OUTPUT":
        return <BooleanIndicator config={config} keyId={key} data={data} />
      case "CHART":
        return <Chart config={config} keyId={key} data={data} />
      case "E-CHART":
        return <Echart config={config} keyId={key} data={data} />
      case "SCATTER CHART":
        return <ScatterChart config={config} keyId={key} data={data} />
      case "BAR CHART":
        return <BarChartNew config={config} keyId={key} data={data} />
      case "PIE CHART":
        return <PieChart config={config} keyId={key} data={data} />
      case "GUAGE":
        return <GuageControl config={config} keyId={key} data={data} />
      case "SLIDER":
        return <SliderControl config={config} keyId={key} data={data} />
      case "STRING":
        return <StringControl config={config} keyId={key} data={data} />
      case "TABLE":
        return <TableControl config={config} keyId={key} data={data} />
      default:
        return ""
    }
  }

  return (
    <>
      <GridLayout
        rowHeight={123}
        cols={8}
        onResize={onResize}
        width={1800}
        layout={layout}
        height={200}
        autoSize={true}
        onLayoutChange={onLayoutChange}
        draggableHandle=".MyDragHandleClassName"
        draggableCancel=".MyDragCancel"
        isResizable={true}
        resizeHandles={['se', 'sw']}
      >
        {grid.map((el, idex) => (
          <div className="bg-transparent" control-id={idex} key={el.key}>
            {control(el.control, { controls: el, id: idex }, el.key)}
          </div>
        ))}
      </GridLayout>
      <Drawer
        title="Edit Configuration"
        bodyStyle={{ backgroundColor: '#001529', padding: 0 }}
        headerStyle={{ backgroundColor: '#001529', padding: 10 }}
        placement="bottom"
        height={400}
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <EditMenu />
      </Drawer>
    </>
  )
}