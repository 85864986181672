
export const button = (value) => {
    return {
        type: 'BUTTON',
    }
}

export const initPage = (value) => {
    return {
        type: 'INIT PAGE',
        value: value
    }
}

export const updateEditMode = (value) => {
    return {
        type: 'EDIT MODE',
        value: value
    }
}


export const numericInput = () => {
    return {
        type: 'NUMERIC INPUT'
    }
}

export const numericRealInput = () => {
    return {
        type: 'NUMERIC REAL INPUT'
    }
}


export const booleanIndicator = () => {
    return {
        type: 'BOOLEAN OUTPUT'
    }
}

export const numeric = () => {
    return {
        type: 'NUMERIC'
    }
}

export const slider = () => {
    return {
        type: 'SLIDER'
    }
}

export const thermometer = () => {
    return {
        type: 'THERMOMETER'
    }
}

export const string = () => {
    return {
        type: 'STRING'
    }
}


export const guage = () => {
    return {
        type: 'GUAGE'
    }
}

export const barChart = () => {
    return {
        type: 'BAR CHART'
    }
}

export const pieChart = () => {
    return {
        type: 'PIE CHART'
    }
}

export const scatterChart = () => {
    return {
        type: 'SCATTER CHART'
    }
}

export const chart = () => {
    return {
        type: 'CHART'
    }
}

export const eChart = () => {
    return {
        type: 'E-CHART'
    }
}

export const table = () => {
    return {
        type: 'TABLE'
    }
}


export const updateLayout = (layouts) => {
    // console.log(layouts)
    return {
        type: 'UPDATE LAYOUT',
        payload: layouts
    }
}


export const enableDrawer = (control) => {
    return {
        type: 'EDIT ENABLE',
        selectedControl: control
    }
}


export const disableDrawer = () => {
    return {
        type: 'EDIT DISABLE'
    }
}


export const upateConfiguration = (method, value, index) => {
    return {
        type: 'UPDATE CONFIGURATION',
        method: method,
        value: value,
        index: index,
    }
}


export const upateChartConfiguration = (method, value, index, channel) => {
    return {
        type: 'UPDATE CHART CONFIGURATION',
        method: method,
        value: value,
        index: index,
        channel: channel
    }
}

export const updateTableConfiguration = (method, value, index, channel) => {
    return {
        type: 'UPDATE TABLE CONFIGURATION',
        method: method,
        value: value,
        index: index,
        channel: channel
    }
}

export const updateJsonData = (value) => {
    return {
        type: 'UPADTE JSON',
        value: value
    }
}

export const updateData = (value) => {
    return {
        type: 'UPADTE DATA',
        value: value
    }
}

export const getJsonData = (value) => {
    return {
        type: 'GET JSON',
        value: value
    }
}

export const updateDataSource = (value) => {
    return {
        type: 'UPADTE DATASOURCE',
        value: value
    }
}


export const deleteControl = (value) => {
    return {
        type: 'DELETE CONTROL',
        value: value
    }
}

export const copyControl = (value, id) => {
    return {
        type: id,
        value: value,
    }
}

export const updateServerStatus = (value) => {
    return {
        type: 'SERVER STATUS',
        value: value,
    }
}