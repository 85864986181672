import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions';

export default function NumericConfig(props) {

    const dispatch = useDispatch()
    const control = useSelector(state => state.variables.selectedControl)
    const config = useSelector(state => state.controls.gridDetails[control])
    const dataSources = useSelector(state => state.datasources.config)
    const sourceList = useSelector(state => state.datasources.source)


    useEffect(() => {
        updateSourceList()
    }, [JSON.stringify(config.config.type)])


    const updateSourceList = () => {
        const source = sourceList[config.config.type]
        if(source){
            let el = ''
            const dataSourcEL = document.getElementById(`datasource-name`)
            source.forEach(element => {
                if (element === 'Select Source') {
                    el += `<option value="">${element}</option>`
                } else {
                    el += `<option value="${element}">${element}</option>`
                }
    
            });
            dataSourcEL.innerHTML = el
        }
    }

    const handleOnChange = (e, method) => {
        switch (method) {
            case 'query':
            case 'source':
            case 'type':
            case 'warningLimit':
            case 'faultLimit':
            case 'precision':
                dispatch(actions.upateConfiguration(method, e.target.value, props.control))
                break;
            default:
                console.log(method)
        }
    }

    const handleDataSource = async (e) => {
        handleOnChange(e, 'type')
        const type = e.target.value
        let source = ['Select Source']
        if (dataSources[type].length !== 0) {
            dataSources[type].forEach(element => {
                if (type === element.type) {
                    source.push(element.name)
                }
            });
        }
        let el = ''
        const dataSourcEL = document.getElementById(`datasource-name`)
        source.forEach(element => {
            if (element === 'Select Source') {
                el += `<option value="">${element}</option>`
            } else {
                el += `<option value="${element}">${element}</option>`
            }

        });
        dataSourcEL.innerHTML = el
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <label className="form-label text-light">Source Type:</label>
                <select className="form-select" 
                aria-label="Default select example"
                 value={config.config.type} 
                 onChange={(e) => handleDataSource(e)}>
                    <option value="">Datasource...</option>
                    <option value="json">JSON</option>
                    <option value="csv">CSV</option>
                    <option value="mysql">MySQL</option>
                    <option value="influx">Influx</option>
                    <option value="plc">PLC</option>
                </select>
                <label className="form-label text-light">Source Name:</label>
                <select
                    className="form-select"
                    id={`datasource-name`}
                    value={config.config.source}
                    onChange={(e) => handleOnChange(e, 'source')}>
                </select>
                <div className="mt-1">
                    <label className="form-label text-light">Numeric Query:</label>
                    <textarea
                        className="form form-control"
                        placeholder="Query"
                        value={config.config.query}
                        onChange={(e) => handleOnChange(e, 'query')} >
                    </textarea>
                </div>
            </div>
            <div className="col-md-1">
                <label className="form-label text-light">Warning:</label>
                <input
                    className="form form-control"
                    type="number"
                    placeholder="Min Value"
                    value={config.config.warningLimit}
                    onChange={(e) => handleOnChange(e, 'warningLimit')}
                />
                <label className="form-label text-light mt-1">Fault:</label>
                <input
                    className="form form-control"
                    type="number"
                    placeholder="Min Value"
                    value={config.config.faultLimit}
                    onChange={(e) => handleOnChange(e, 'faultLimit')}
                />
                <label className="form-label text-light mt-1">Precision:</label>
                <input
                    className="form form-control"
                    type="number"
                    max="3"
                    min="0"
                    placeholder="No of Precision"
                    value={config.config.precision}
                    onChange={(e) => handleOnChange(e, 'precision')}
                />
            </div>
        </div>
    )
}