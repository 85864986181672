import React from "react";
import PLC from './plc';
import Influx from './influx';
import MySQL from './mySQL';
import CSV from './csv';
import JSON from './json';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import HTHeader from '../../components/header';

export default class AddDataSource extends React.Component {

    state = {
        defaultPage: '',
        visible: false
    }

    componentDidMount = () => {
        if (this.props.history.location.state) {
            this.setState({ visible: false })
        } else {
            this.setState({ visible: true })
        }
    }

    render() {
        return (
            <div className="container-fluid mt-0">
            <HTHeader />
            <Tab.Container id="left-tabs-example" defaultActiveKey={this.props.history.location.state ? this.props.history.location.state.type : ''}>
                <Row>
                    <Col sm={1} className="">
                        {this.state.visible ?
                            <Nav variant="pi1lls" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="csv" className="text-center">
                                        <i className="fas fa-file-csv text-warning fa-2x"></i>
                                        <p><span className="text-warning fw-bold" style={{ fontSize: 10 }}>CSV</span></p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="json" className="text-center">
                                        <i className="fas fa-file-csv text-warning fa-2x"></i>
                                        <p><span className="text-warning fw-bold" style={{ fontSize: 10 }}>JSON</span></p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="plc" className="text-center">
                                        <i className="fas fa-memory text-warning fa-2x"></i>
                                        <p><span className="text-warning fw-bold" style={{ fontSize: 10 }}>PLC</span></p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="mysql" className="text-center">
                                        <i className="fas fa-database text-warning fa-2x"></i>
                                        <p><span className="text-warning fw-bold" style={{ fontSize: 10 }}>MySQL</span></p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="influx" className="text-center">
                                        <i className="fas fa-database text-warning fa-2x"></i>
                                        <p><span className="text-warning fw-bold" style={{ fontSize: 10 }}>Influx DB</span></p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="text-center">
                                        <Link className="text-warning fw-bold" style={{ fontSize: 10 }} to="/">Home</Link>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav> : ''}
                    </Col>
                    <Col sm={11} className="text-light">
                        <Tab.Content>
                            <Tab.Pane eventKey="plc">
                                <PLC data={this.props.history.location.state ? this.props.history.location.state.data : null} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="csv">
                                <CSV data={this.props.history.location.state ? this.props.history.location.state.data : null} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="json">
                                <JSON data={this.props.history.location.state ? this.props.history.location.state.data : null} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="mysql">
                                <MySQL data={this.props.history.location.state ? this.props.history.location.state.data : null} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="influx">
                                <Influx data={this.props.history.location.state ? this.props.history.location.state.data : null} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            </div>
        )
    }
}


