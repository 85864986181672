import {Link} from 'react-router-dom';
import Toolbar from '../toolBar';
import { Layout, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';

const { Header, Content, Footer } = Layout;


function HTHeader (props) {

	const [currentTime, setcurrentTime] = useState('')
	const [editMode, setEditMode] = useState(false)
	const dispatch = useDispatch()
	
	useEffect(() => {
		const updateTime = setInterval(() => {
			setcurrentTime(moment().format('L') + ' ' + moment().format('LTS'))
		}, 1000);

		return () => {
			clearInterval(updateTime)
		}
	}, [])

	const onChange = (checked) => {
		setEditMode(checked)
		dispatch(actions.updateEditMode(checked))
	  }
	


	return(
		<Header className="site-layout-background" style={{ padding: 0 }}>
			<div className="text-muted fw-bold text-center fs-2">
				<Link className="text-muted mt-2 mb-4" to={props.main ? '/' :'/dashboard'} >HT Dashboard {props.page && props.page.name}</Link>
			</div>
		</Header> 
	)
};

export default HTHeader;
