import React from 'react';
import Card from '../../card';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';


// const { createSliderWithTooltip } = Slider;
// const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

export default class SliderControl extends React.Component{

    constructor(){
      super();
      this.state= {
        data: 222,
        min:0,
        max:2000
      }
    }

    handle = props => {
        const { value, dragging, index, ...restProps } = props;
        return (
          <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value}`}
            visible={dragging}
            placement="top"
            key={index}
          >
            <Handle value={value} {...restProps} />
          </SliderTooltip>
        );
      };

    cardContent = () => (
        <Slider 
            min={this.state.min} 
            max={this.state.max} 
            defaultValue={this.state.data} 
            handle={this.handle}
            trackStyle={[{ backgroundColor: '#ffb300' }]}
            handleStyle={[{ backgroundColor: '#212121' }]}
            railStyle={{ backgroundColor: '#fff' }}
        />
  )
                
    render(){
    return(
        <Card config={this.props.config} cardContent={this.cardContent} />
    )
  }
}