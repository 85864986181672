import React, { useState, useEffect } from 'react';
import { Layout, Menu, Popconfirm, message, Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import HTFooter from '../../components/footer';
import { dashBoardConstants } from '../../constants';
import { Link } from 'react-router-dom';
import Lottie from "react-lottie";
import dashLottie from '../../animations/dash.json';


const { Header, Content, Footer } = Layout;
const constants = dashBoardConstants()
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: dashLottie,
};


function PagesList (props){

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageName, setPageName] = useState('')
    const [pages, setPageDetails] = useState([])


    useEffect(() => {
        getPageConfiguration()
	}, [])

      const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        updateConfiguration()
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };
    
      const getPageConfiguration = async () => {
        const res = await fetch(constants.url + 'pageDetails/')
        const data = await res.json()
        setPageDetails(data.reverse())
    }
    
    function confirm(page) {
        deletePage(page)
      }
      
    const cancel = () => {}

    const updateConfiguration = async () => {
        try{
          const res1 = await fetch(constants.url + 'pageDetails/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: pageName,
              config: { type: [], layout: [], gridDetails: [] }
            })
          })
          const ra = await res1.json()
          await message.success(ra.message)
          getPageConfiguration()
        } catch (err){
          message.error('Something went wrong. Please try again later')
        }
      } 

      const deletePage = async (id) => {
        try{
            const res = await fetch(constants.url + 'pageDetails/'+id, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json'
                },
              })
            const data = await res.json()
            message.info(data.message)
            getPageConfiguration()
        } catch {
            message.error('Something went wrong. Please try again later')
        }
      }


        return (
            <div>
                {/* <HTHeader page={{}} main={true}/> */}
                <div className="container">
                    <div className="row align-self-center align-items-center" style={{ height:'90vh' }}> 
                        <div className="col-md-7 text-center align-self-center rounded shadow-lg">
                            {/* <span className="company-name">Hawfinch</span>
                            <br />
                            <span className="text-muted">Technologies Private Limited</span>
                            <hr /> */}
                            <h1 className="text-muted fw-bold company-tag-line">HT Dashboard</h1>
                            <Lottie options={defaultOptions} height={'100%'} width={'100%'}/>
                            <p className="text-muted" style={{ textAlign:'justify' }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4 text-center text-light">
                            <span className="company-name">Hawfinch</span>
                            <br />
                            <span className="text-muted">Technologies Private Limited</span>
                            <hr />
                            <h1 className="text-light h5 text-muted text-start">Pages List:</h1>
                            <button className="btn text-primary" onClick={showModal}>+ Add New Page</button>
                            <ul className="list-group">
                                {pages.map((e, idx) => (
                                    <li key={`dash-page-list-${idx}`} className="list-group-item p-0 mt-2 border-0" style={{ background: 'transparent' }}>
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <Link 
                                                        className="nav-link text-start" 
                                                        to={{
                                                            pathname: '/dashboard',
                                                            state: e
                                                        }}
                                                        ><span className="text-muted text-start">{e.name}</span>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2">
                                                <Popconfirm
                                                    title="Are you sure to delete this task?"
                                                    onConfirm={() => confirm(e.id)}
                                                    onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <span className="text-danger"><i className="fas fa-trash"></i></span>
                                                </Popconfirm>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <HTFooter />
                <Modal 
                    title="Save Page Configuration" 
                    theme='dark' 
                    visible={isModalVisible} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                        Return
                        </Button>,
                        <Button key="submit" type="primary" loading={false} onClick={handleOk}>
                        Submit
                        </Button>,
                    ]}
                    >
                        <input className="form form-control" type="text" value={pageName} onChange={(e) => setPageName(e.target.value)} />
                    </Modal>
            </div>
        )

}

export default PagesList;