import React from "react";
import { setConfig } from '../api';
import { notification } from 'antd';


export default class PLC extends React.Component {

    state = {
        config: {
            name: '',
            type: 'plc',
            config: {
                ip0: '',
                ip1: '',
                ip2: '',
                ip3: '',
                rack: '',
                slot: '',
                port: '',
                tags: []
            }
        },
        parameter: {
            name: '',
            address: ''
        },
        edit: false,
        parameterEdit: false
    }

    componentDidMount = () => {
        try {
            if (this.props.data.type === 'plc') {
                this.setState({ config: this.props.data })
                this.setState({ edit: true })
            } else {
                this.setState({ edit: false })
            }
        } catch {

        }

    }

    openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
            duration: 3,
            placement: 'bottomRight',
            className: `${type === 'error' ? 'bg-danger' : 'bg-success'} rounded`,
            style: {
                color: '#fff!important'
            },
            description:
                '',
        });
    };

    handleOnChange = (e, t) => {
        const config = JSON.parse(JSON.stringify(this.state.config))
        switch (t) {
            case 'name':
                config['name'] = e.target.value
                break;
            default:
                config['config'][t] = e.target.value
        }
        this.setState({ config: config })
    }

    saveConfig = async (e) => {
        e.preventDefault()
        const method = this.state.edit ? 'PUT' : 'POST'
        const url = this.state.edit ? `datasource/${this.state.config.id}/` : `datasource/`
        const res = await setConfig(url, this.state.config, method)
        this.openNotificationWithIcon(res.error ? 'error' : 'success', res.message)
    }

    handleAddParameter = (e) => {
        const config = JSON.parse(JSON.stringify(this.state.config))
        let tagFound = { exist: false, index: 0 };
        e.preventDefault()
        if (this.state.parameter.name !== '' && this.state.parameter.address !== '') {
            let index = 0
            for (let dataTag of config.config.tags) {
                if (dataTag.name === this.state.parameter.name) {
                    tagFound.exist = true
                    tagFound.index = index
                    break
                }
                index += 1
            }
            if (!tagFound.exist) {
                config.config['tags'].push(this.state.parameter)
                this.setState({ config: config })
            } else {
                // alert('Do you want to update the changes?')
                config.config.tags[tagFound.index] = this.state.parameter
                this.setState({ config: config })
            }
        } else {
            this.openNotificationWithIcon('error', 'Parameter & Address field should not be empty...')
        }
    }

    handleParameterOnChange = (e, t) => {
        const parameter = JSON.parse(JSON.stringify(this.state.parameter))
        switch (t) {
            case 'name':
                parameter['name'] = e.target.value
                break;
            default:
                parameter['address'] = e.target.value
        }
        this.setState({ parameter: parameter })
    }

    handleEditParameter = (idx) => {
        this.setState({ parameterEdit: true })
        let config = JSON.parse(JSON.stringify(this.state.config))
        this.setState({ parameter: { name: config.config.tags[idx].name, address: config.config.tags[idx].address } })

    }

    handleDeleteParameter = (idx) => {
        let config = JSON.parse(JSON.stringify(this.state.config))
        config.config.tags.splice(idx, 1)
        this.setState({ config: config })
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-5 mb-5 text-center">
                    <div className="col-md-2 offset-md-5">
                        <img src="./plc.png" alt="datasour1ce-logo" className="mb-4 img-fluid"></img>
                    </div>
                    <h1 className="h1 text-light">SIEMENS PLC Configuration</h1>
                </div>
                <div className="row vh-100 align-ite1ms-center">
                    <div className="col-md-6 offset-md-3">
                        <div className="card shadow">
                            <div className="card-body">
                                <form className="form">
                                    <div className="row" >
                                        <div className="mb-3">
                                            <label className="form-label">Source Name</label>
                                            <input type="text"
                                                className="form-control"
                                                onChange={(e) => this.handleOnChange(e, 'name')}
                                                value={this.state.config.name}
                                                placeholder="Sample Source Name" />
                                        </div>
                                    </div>

                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#plc" type="button" role="tab" aria-controls="home" aria-selected="true">PLC Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#tag" type="button" role="tab" aria-controls="profile" aria-selected="false">Tag Details</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="plc" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="row mb-3">
                                                <label className="form-label">IP address</label>
                                                <div className="col">
                                                    <input type="number" className="form-control" min="0" max="255" placeholder="IP1" aria-label="IP1"
                                                        value={this.state.config.config.ip0}
                                                        onChange={(e) => this.handleOnChange(e, 'ip0')}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input type="number" className="form-control" min="0" max="255" placeholder="IP2" aria-label="IP2"
                                                        value={this.state.config.config.ip1}
                                                        onChange={(e) => this.handleOnChange(e, 'ip1')}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input type="number" className="form-control" min="0" max="255" placeholder="IP3" aria-label="IP3"
                                                        value={this.state.config.config.ip2}
                                                        onChange={(e) => this.handleOnChange(e, 'ip2')}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input type="number" className="form-control" min="0" max="255" placeholder="IP4" aria-label="IP4"
                                                        value={this.state.config.config.ip3}
                                                        onChange={(e) => this.handleOnChange(e, 'ip3')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="mb-3">
                                                    <label className="form-label">Rack#</label>
                                                    <input type="number" className="form-control" id="plc-rack" min="0" max="10" placeholder="Rack"
                                                        value={this.state.config.config.rack}
                                                        onChange={(e) => this.handleOnChange(e, 'rack')}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Slot#</label>
                                                    <input type="number" className="form-control" id="plc-slot" min="0" max="10" placeholder="Slot"
                                                        value={this.state.config.config.slot}
                                                        onChange={(e) => this.handleOnChange(e, 'slot')}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Port#</label>
                                                    <input type="number" className="form-control" id="plc-port" min="0" max="255" placeholder="Port"
                                                        value={this.state.config.config.port}
                                                        onChange={(e) => this.handleOnChange(e, 'port')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="tag" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="DB1,INT0"
                                                        onChange={(e) => this.handleParameterOnChange(e, 'address')}
                                                        value={this.state.parameter.address}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        onChange={(e) => this.handleParameterOnChange(e, 'name')}
                                                        value={this.state.parameter.name} />
                                                </div>
                                                <div className="col">
                                                    <button className="btn btn-warning" onClick={this.handleAddParameter}><i className="fas fa-plus-circle"></i></button>
                                                </div>
                                            </div>

                                            <table className="table mt-3 text-light">
                                                <thead>
                                                    <tr className="text-light">
                                                        <th>Address</th>
                                                        <th>Parameter Name</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.config.config.tags && this.state.config.config.tags.map((e, idx) => (
                                                        <tr key={`plc-tag-${idx}`}>
                                                            <td>{e.address}</td>
                                                            <td>{e.name}</td>
                                                            <td><i className="far fa-edit" onClick={() => this.handleEditParameter(idx)} ></i></td>
                                                            <td><i className="far fa-trash-alt" onClick={() => this.handleDeleteParameter(idx)}></i></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="row p-2 mt-3">
                                        <button className="col form-control btn btn-warning" onClick={this.saveConfig}>{this.state.edit ? 'Update' : 'Save'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}