import React from 'react';
import {
    Menu,
    MenuItem,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { Popconfirm } from 'antd';



export default function Card (props){

    const dispatch = useDispatch()
    const editMode = useSelector(state => state.common.edit)

    const confirm = (e) => {
        dispatch(actions.deleteControl(props.config.id))
      }

    const handleMenuClick = (e) => {
        const menuState = e.value
        switch(menuState){
            case 'edit':
                dispatch(actions.enableDrawer(props.config.id))
                break;
            case 'delete':
                // dispatch(actions.deleteControl(props.config.id))
                break;
            case 'copy':
                dispatch(actions.copyControl(props.config.id, props.config.controls.control))
                break;
            default:
                console.log(e)
        }
    }   

    const menu = () => {
        return (
            <>
            <Menu 
                menuButton={<i className="fas fa-ellipsis-h"></i>}
            >
                <MenuItem value="edit" onClick={handleMenuClick}>
                    <span className="fw-bold text-primary">Edit</span>
                </MenuItem>
                <MenuItem value="delete" onClick={handleMenuClick}>
                    <Popconfirm
                        theme="dark"
                        title="Are you sure to delete this control?"
                        onConfirm={confirm}
                        okText="Yes"
                        cancelText="No"
                    >
                    <a className="fw-bold text-decoration-none text-danger">Delete</a>
                </Popconfirm>
                </MenuItem>
                <MenuItem value="copy" onClick={handleMenuClick}>
                    <span className="fw-bold">Copy</span>
                </MenuItem>
            </Menu>
            </>
        );
    }



    return(
        <div className="card dash-control text-center p-2 h-100 rounded" style={{ borderRadius: 40 }}>
            <div className="card-header bg-transparent">
                <div className="d-flex justify-content-between" >
                    {editMode &&
                <span className="control-menu">{menu()}</span>}
                    <h1 className="fw-bold text-center heading w-100 MyDragHandleClassName control-header mb-1" style={{  }}>
                        {props.config.controls.name} {props.config.controls.unit !== '' ? <span className="control-unit text-warning" >{props.config.controls.unit}</span>:''}
                    </h1>
                </div>
            </div>
            <div className="card-body p-0 d-flex justify-content-center align-items-center h-100">
                {props.cardContent()}
            </div>
        </div>
    )
}