import React from "react";
import {setConfig} from '../api';
import { notification } from 'antd';

export default class CSVConfiguration extends React.Component{

    state = {
        config:{
            name: '',
            type:'csv',
            config: {
                url: ''
            }
        },
        edit: false
    }

    componentDidMount = () => {
        if(this.props.data){
            this.setState({config: this.props.data})
            this.setState({edit: true})
        } else {
            this.setState({edit: false})
        }
    }

    openNotificationWithIcon = (type, msg) => {
        notification[type]({
          message: msg,
          duration: 3,
          placement: 'bottomRight',
          className: `${type === 'error' ? 'bg-danger' : 'bg-success'} rounded` ,
          style: {
            color: '#fff!important'
          },
          description:
            '',
        });
      };

    handleOnChange = (e, t) => {
        const config = this.state.config
        switch(t){
            case 'name':
                config['name'] = e.target.value
                break;
            case 'url':
                config['config']['url'] = e.target.value
                break;
            default:
        }
        this.setState({config: config})
    }

    saveConfig = async(e) => {
        e.preventDefault()
        const method = this.state.edit ? 'PUT' : 'POST'
        const url = this.state.edit ? `datasource/${this.state.config.id}/` : `datasource/`
        const res = await setConfig(url, this.state.config, method)
        this.openNotificationWithIcon(res.error ? 'error' : 'success', res.message)
    }

    render(){
        return(
            <div className="container">
                <div className="row mt-5 mb-5 text-center">
                    <div className="col-md-2 offset-md-5">
                        <img src="./csv.png" alt="datasource-logo" className="mb-4 img-fluid"></img>
                    </div>
                    <h1 className="h1 text-light">CSV Configuration</h1>
                </div>
                <div className="row vh-100 align-ite1ms-center">
                    <div className="col-md-6 offset-md-3">
                        <div className="card shadow">
                            <div className="card-body">
                            <form className="form">
                                    <div className="row" >
                                        <div className="mb-3">
                                            <label className="form-label">Source Name</label>
                                            <input type="text" 
                                                className="form-control" 
                                                onChange={(e) => this.handleOnChange(e, 'name')} 
                                                value={this.state.config.name} 
                                                placeholder="Sample Source Name" />
                                        </div>
                                    </div>
                                        <div className="row mb-3">
                                            <h1 className="h5 text-light">CSV Details:</h1>
                                            <div className="col">
                                                <label className="form-label">URL</label>
                                                <textarea 
                                                 className="form-control" 
                                                 onChange={(e) => this.handleOnChange(e, 'url')} 
                                                 value={this.state.config.config.url} 
                                                 placeholder="http://localhost/data" />
                                            </div>
                                        </div>

                                    <div className="row p-2 mt-3">
                                        <button className="col form-control btn btn-warning" onClick={this.saveConfig}>{this.state.edit ? 'Update' : 'Save'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}