import { dashBoardConstants } from '../../constants';

const dashBoardConfig = dashBoardConstants()


export const getConfig = async (url) => {
    let data = ""
    try {
        const response = await fetch(dashBoardConfig.url + url)
        data = await response.json()
    } catch (error) {
        data = { "message": "Something went wrong.", "show": true }
    }
    return data
}

export const setConfig = async (url, configData, method) => {
    let data = ""
    try {
        const response = await fetch(dashBoardConfig.url + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(configData)
        })
        data = await response.json()
    } catch (error) {
        data = { "message": "Something went wrong. Please check the connection", "error": true }
    }
    return data
}


export const deleteConfig = async (url) => {
    let data = ""
    try {
        const response = await fetch(dashBoardConfig.url + url, {
            method: 'DELETE'
        })
        data = await response.json()
    } catch (error) {
        data = { "message": "Something went wrong.", "error": true }
    }
    return data
}
